<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>C端数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户注册信息</el-breadcrumb-item>
      <el-breadcrumb-item>注册信息详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 用户基础信息 -->
    <!-- 用户基本信息 -->
    <el-card>
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">基础信息</span>
        </el-col>
      </el-row>
      <el-form
        label-width="130px"
        :model="userDetail"
        ref="userDetailRef"
        :rules="userDetailRules"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="账号" prop="phone">
              <!-- <el-input v-model="userDetail.phone" disabled></el-input> -->
              <div class="account">
                <span class="txt">{{ userDetail.phone }}</span>
                <el-button type="text" @click="resetPassword"
                  >重置密码</el-button
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="国籍" prop="country">
              <el-input
                v-model="userDetail.country"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份" prop="identity">
              <el-select
                placeholder="请选择（默认普通用户）"
                style="width: 100%"
                v-model="userDetail.identity"
                clearable
              >
                <el-option
                  v-for="item in dict_role"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="userDetail.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="英文名" prop="enName">
              <el-input
                v-model="userDetail.enName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" prop="sex">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="userDetail.sex"
                clearable
              >
                <el-option
                  v-for="item in dict_sex"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年级" prop="grade">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="userDetail.grade"
                clearable
              >
                <el-option
                  v-for="item in dict_grade"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="班级" prop="classes">
              <el-input
                v-model="userDetail.classes"
                placeholder="请输入"
                type="number"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学校地区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                style="width: 100%"
                v-model="userDetail.schoolArea"
                :options="areasData"
                clearable
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'areaId',
                  children: 'areas',
                }"
                @change="provincesHandleChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学校名称" prop="schoolId">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="userDetail.schoolId"
                clearable
              >
                <el-option
                  v-for="item in schoolDataList"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="机构地区" prop="institutionArea">
              <el-cascader
                placeholder="请选择"
                style="width: 100%"
                v-model="userDetail.institutionArea"
                :options="areasData"
                clearable
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'areaId',
                  children: 'areas',
                }"
                @change="provincesHandleChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="机构名称" prop="institutionId">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="userDetail.institutionId"
                clearable
              >
                <el-option
                  v-for="item in institutionDataList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="证件类型" prop="cardType">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="userDetail.cardType"
                clearable
              >
                <el-option
                  v-for="item in dict_idCardType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="证件号" prop="idCard">
              <el-input
                v-model="userDetail.idCard"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="国赛保留名额" prop="nationalReserve">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="userDetail.nationalReserve"
                clearable
              >
                <el-option
                  v-for="item in dict_countryReserve"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="IBC选手" prop="ibcIdentity">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="userDetail.ibcIdentity"
                clearable
              >
                <el-option
                  v-for="item in dict_countryReserve"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="saveUserDeatil"
            >保存</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- <el-card style="margin-top: 15px">
      <el-descriptions title="用户信息" border>
        <el-descriptions-item label="手机号">{{
          userInfo.phone
        }}</el-descriptions-item>
        <el-descriptions-item label="用户名">{{
          userInfo.userName
        }}</el-descriptions-item>
        <el-descriptions-item label="性别">
          <template>
            <el-tag type="success" v-if="userInfo.sex === '1'" size="small"
              >男</el-tag
            >
            <el-tag type="danger" v-else-if="userInfo.sex === '0'" size="small"
              >女</el-tag
            >
            <span v-else>-</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="国籍">{{
          userInfo.nationality ? userInfo.nationality : '-'
        }}</el-descriptions-item>
        <el-descriptions-item label="证件类型">
          <template>
            <el-tag type="danger" v-if="userInfo.cardType === '1'" size="mini"
              >身份证</el-tag
            >
            <el-tag
              type="success"
              v-else-if="userInfo.cardType === '2'"
              size="mini"
              >护照</el-tag
            >
            <span v-else>-</span>
          </template></el-descriptions-item
        >
        <el-descriptions-item label="证件号">{{
          userInfo.idCard
        }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{
          userInfo.email
        }}</el-descriptions-item>
        <el-descriptions-item label="学校省市区"
          >{{ userInfo.province }}/{{ userInfo.city }}/{{
            userInfo.area
          }}</el-descriptions-item
        >
        <el-descriptions-item label="学校名">{{
          userInfo.schoolName
        }}</el-descriptions-item>
        <el-descriptions-item label="年级"
          >{{ userInfo.grade }}年级</el-descriptions-item
        >
        <el-descriptions-item label="组别">{{ getLevelLabel(userInfo.level) }}</el-descriptions-item>
      </el-descriptions>
    </el-card> -->
    <!-- 地址列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">地址信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="userInfoAddressList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column
          label="收件人姓名"
          prop="receiptName"
        ></el-table-column>
        <el-table-column
          label="收件人手机号"
          prop="receiptPhone"
        ></el-table-column>
        <el-table-column label="省市区">
          <template slot-scope="scope">
            <span
              >{{ scope.row.province }}-{{ scope.row.city }}-{{
                scope.row.area
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="详细地址"
          prop="receiptAddress"
        ></el-table-column>
        <el-table-column label="默认地址" prop="isDefault">
          <template slot-scope="scope">
            {{ scope.row.isDefault === 0 ? "否" : "是" }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 报名信息 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">报名信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="赛季" prop="season"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column label="学校" prop="schoolName">
          <template slot-scope="scope">
            <span
              v-if="scope.row.fillschoolName && scope.row.fillschoolName !== ''"
              >{{ scope.row.fillschoolName }}</span
            >
            <span v-else>{{ scope.row.schoolName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="组别"
          prop="enrollmentLevel"
          :formatter="groupFormatter"
        ></el-table-column>
        <el-table-column
          label="年级"
          prop="grade"
          :formatter="gradeFormatter"
        ></el-table-column>
        <el-table-column label="联系方式" prop="phone"></el-table-column>
        <el-table-column
          label="开通产品名称"
          prop="productName"
        ></el-table-column>
        <el-table-column
          label="开通类型"
          prop="openType"
          :formatter="openTypeFormatter"
        ></el-table-column>
        <el-table-column label="参赛方式" prop="isOnline" width="125px">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.score ? 'success' : 'danger'"
              v-if="scope.row.isOnline + '' === '1'"
              size="mini"
              >线上参赛({{ scope.row.score ? "已答题" : "未答题" }})</el-tag
            >
            <el-tag type="warning" v-else size="mini">线下参赛</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="开通时间" prop="createTime"></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
      </el-table>
    </el-card>

    <!-- 订单列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">订单信息</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="orderList"
        stripe
        @expand-change="expandFn"
      >
        <el-table-column type="expand" class="expand-td">
          <template slot-scope="scope">
            <el-table
              :data="scope.row.productList"
              header-row-class-name="table-head-row"
              header-cell-class-name="table-head-cell"
              cell-class-name="table-head-cell"
            >
              <el-table-column label="赛季" prop="season"></el-table-column>
              <el-table-column
                label="产品名称"
                prop="productName"
                width="300px"
              ></el-table-column>
              <el-table-column
                label="赛程"
                prop="raceSchedule"
                :formatter="raceScheduleFormatter"
              ></el-table-column>
              <el-table-column
                label="赠送词库到期时间"
                prop="expiryDate"
                width="180px"
                :formatter="expiryDateFormatter"
              ></el-table-column>
              <el-table-column
                label="数量"
                prop="productNum"
                width="100px"
              ></el-table-column>
              <el-table-column
                label="总价"
                prop="totalAmount"
              ></el-table-column>
              <el-table-column label="单价" prop="unitPrice"></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="赛季" prop="season"></el-table-column>
        <el-table-column
          label="订单名称"
          prop="orderName"
          width="150px"
        ></el-table-column>
        <el-table-column label="订单号" prop="orderNo"></el-table-column>
        <el-table-column label="订单金额" prop="orderAmount"></el-table-column>
        <el-table-column
          label="实付金额"
          prop="paymentAmount"
        ></el-table-column>
        <el-table-column
          label="订单来源"
          prop="orderSource"
          :formatter="sourceFormatter"
        ></el-table-column>
        <el-table-column label="下单时间" prop="orderTime"></el-table-column>
        <!-- <el-table-column label="是否寄送" prop="isSend" width="100px">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.isSend + '' === '1'"
              size="mini"
              >需要寄送</el-tag
            >
            <el-tag type="warning" v-else size="mini">无需寄送</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="订单状态" prop="orderStatus" width="100px">
          <template slot-scope="scope">
            <el-tag
              v-if="findStatus(scope.row.orderStatus)"
              :type="findStatus(scope.row.orderStatus).type"
              size="mini"
              >{{ findStatus(scope.row.orderStatus).dictLabel }}</el-tag
            >
            <!-- <el-tag style="color: #28526c" v-if="scope.row.orderStatus + '' === '1'" size="mini">待支付</el-tag>
            <el-tag type="warning" v-else-if="scope.row.orderStatus + '' === '2'" size="mini">待发货</el-tag>
            <el-tag type="primary" v-else-if="scope.row.orderStatus + '' === '3'" size="mini">已发货</el-tag>
            <el-tag style="color: #498327" v-else-if="scope.row.orderStatus + '' === '4'" size="mini">订单完成</el-tag>
            <el-tag style="color: #fc530a" v-else-if="scope.row.orderStatus + '' === '5'" size="mini">申请退款</el-tag>
            <el-tag style="color: #fcbc07" v-else-if="scope.row.orderStatus + '' === '6'" size="mini" >退款审核中</el-tag>
            <el-tag type="success" v-else-if="scope.row.orderStatus + '' === '7'" size="mini">退款成功</el-tag>
            <el-tag type="danger" v-else-if="scope.row.orderStatus + '' === '8'" size="mini">退款失败</el-tag>
            <el-tag type="info" v-else-if="scope.row.orderStatus + '' === '9'" size="mini">订单关闭</el-tag> -->
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { getAllAreaList, getSysUserInfo, getSysUserAddressListByUserId, queryTbOrderList, queryProductOrderList, queryTbEnrollmentList, getSchoolListWithCity } from '@/http/api'
export default {
  data () {
    return {
      areasData: [],
      dict_idCardType: this.$userInfo.dict_idCardType(),
      dict_role: this.$userInfo.dict_role(),
      dict_sex: this.$userInfo.dict_sex(),
      userDetail: {},
      userInfo: {},
      schoolDataList: [], // 学校列表
      institutionDataList: [], // 机构列表
      userInfoAddressList: [],
      userId: this.$route.query.userId,
      orderList: [],
      dataList: [],
      orderStatusList: this.$userInfo.orderStatus(),
      order_source: this.$userInfo.orderSource(),
      dict_group: this.$userInfo.dict_group(),
      dict_grade: this.$userInfo.getGradeData(),
      p_open_type: this.$userInfo.getDataList(this, 'p_open_type').then(value => { this.p_open_type = value }),
      dict_countryReserve: [
        {
          dictCode: 1,
          dictLabel: '是',
          dictValue: true
        },
        {
          dictCode: 2,
          dictLabel: '否',
          dictValue: false
        }
      ],
      queryForm: {
        phone: '',
        name: '',
        season: this.$chnEngStatusCode.defaultSeason,
        group: '',
        schoolId: '',
        pageNum: 1,
        pageSize: 10000,
        userName: '',
        userId: this.$route.query.userId,
        ibcIdentity: null
      },
      userDetailRules: {
        identity: [{ required: true, message: '请选择', trigger: 'change' }],
        sex: [{ required: true, message: '请选择', trigger: 'change' }],
        grade: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }],
        idCardType: [{ required: true, message: '请选择', trigger: 'change' }],
        userName: [{ required: true, message: '请输入', trigger: 'blur' }],
        idCardNum: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      order_status: this.$userInfo.orderStatus()
    }
  },
  created () {
    console.log(this.orderStatusList)
    this.getUserInfoData()
    this.getUserInfoAddressList()
    this.queryTbOrderList()
    this.getDataList()
    this.getAllAreaList()
  },
  methods: {
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    findStatus (status) {
      return this.order_status.find(item => item.dictCode === status)
    },
    // 获取用户信息详情
    getUserInfoData () {
      getSysUserInfo({ userId: this.userId }).then((res) => {
        console.log('getSysUserInfo', res)
        var userInfo = res.data
        userInfo.schoolArea = [userInfo.provinceCode + '', userInfo.cityCode + '', userInfo.areaCode + '']
        this.userDetail = userInfo
        if (userInfo.schoolId !== undefined && userInfo.schoolId !== '') {
          this.getDataListByAreaId(0)
        }
        if (userInfo.institutionId !== undefined && userInfo.institutionId !== '') {
          this.getDataListByAreaId(1)
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取用户地址列表
    getUserInfoAddressList () {
      getSysUserAddressListByUserId({ userId: this.userId }).then((res) => {
        console.log('getSysUserAddressListByUserId', res)
        this.userInfoAddressList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取报名列表
    getDataList () {
      queryTbEnrollmentList(this.queryForm).then((res) => {
        this.dataList = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 根据省市区获取学校
    getDataListByAreaId (dataType) {
      if (dataType === 0) { // 请求学校/幼儿园数据
        this.getSchoolListData([this.userDetail.provinceCode, this.userDetail.cityCode, this.userDetail.areaCode])
      }
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      if (!value) return
      this.userDetail.schoolArea = value
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      console.log('schoolArea', schoolArea)
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.schoolDataList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 保存用户信息
    saveUserDeatil () {
      this.$refs.userDetailRef.validate(async valid => {
        if (!valid) return
        if (this.userDetail.idCardType === '0') {
          if (!this.$xcUtils.checkIdCard(this.userDetail.idCardNum)) {
            return this.$message.warning('请填写正确的证件号')
          }
        }
        const { data: res } = await this.$http.post('/erpUser/updateSysUser', this.userDetail)
        console.log('updateSysUser', res)
        if (res.code !== 0) {
          return this.$message.error(res.message)
        }
        this.$message.success('保存成功')
      })
    },
    // 订单
    queryTbOrderList () {
      queryTbOrderList({ userId: this.userId }).then(res => {
        res.data.forEach(item => {
          item.productList = []
          item.expanded = false
        })
        this.orderList = res.data
      })
    },
    expandFn (row) {
      if (!row.expanded) {
        queryProductOrderList({ orderNo: row.orderNo, userId: this.userId }).then(res => {
          const productList = res.data
          this.orderList.find(item => item.orderNo === row.orderNo).productList = productList
        })
      }
      row.expanded = !row.expanded
    },
    sourceFormatter (data) {
      var temp = '未设置'
      for (const key in this.order_source) {
        if (Object.hasOwnProperty.call(this.order_source, key)) {
          const element = this.order_source[key]
          if (element.dictValue === data.orderSource + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    expiryDateFormatter (data) {
      return data.expiryDate || '-'
    },
    raceScheduleFormatter (row) {
      const data = row.raceSchedule
      return data === 1 ? '城市初赛' : data === 2 ? '城市复赛' : data === 3 ? '城市区域赛' : data === 4 ? '全国总决赛' : '-'
    },
    getLevelLabel (level) {
      return level ? this.dict_group.find(item => item.dictCode === level).dictLabel : '-'
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue === data.enrollmentLevel) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    openTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.p_open_type) {
        if (Object.hasOwnProperty.call(this.p_open_type, key)) {
          const element = this.p_open_type[key]
          if (element.dictValue === data.openType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    resetPassword () {
      this.$confirm('重置后原密码作废，请谨慎操作', '确认重置？', {
        confirmButtonText: '仍要重置',
        cancelButtonText: '取消'
      }).then(() => {
        const password = String(parseInt(Math.random() * 1000000) + 1000000).substring(1, 7)
        this.$http.post('/erpUser/resetPassword', { password, userId: this.userId }).then(res => {
          if (res.code === 200) {
            this.$alert(`
              <div style="text-align: center; margin-bottom: 12px;"><strong style="font-size: 24px;color:#000;">${password}</strong></div>
              <div>新密码由系统随机生成，用户可通过小程序“忘记密码”验证手机号码后再次重置</div>`, '重置成功！', {
              dangerouslyUseHTMLString: true
            })
          }
        })
      }).catch(action => {
        console.log(action)
      })
    }
  }
}
</script>

<style scoped lang="less">
::v-deep .el-table__expanded-cell .table-head-row {
  color: #606266;
}
::v-deep .el-table__expanded-cell .table-head-cell {
  background-color: #f5f7fa !important;
  /* border-bottom-color: #dedede !important; */
}
::v-deep .el-table__expanded-cell {
  padding: 0 !important;
}
::v-deep .el-table__expanded-cell .el-table {
  margin-top: 0;
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  background-color: #f0f8ff !important;
  color: #606266 !important;
  font-size: 12px !important;
  font-weight: bold;
  width: 140px;
}
::v-deep .el-descriptions__title {
  font-weight: normal;
  display: flex;
  align-items: center;
}
::v-deep .el-descriptions__title::before {
  display: block;
  content: "";
  width: 3px;
  height: 18px;
  border-radius: 3px;
  background-color: #409eff;
  margin-right: 6px;
}
.account {
  height: 40px;
  line-height: 40px;
  .txt {
    margin-right: 12px;
    font-size: 16px;
  }
}
</style>
